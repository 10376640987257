import { create } from 'zustand';

import { getApiInstance } from '@nm-namshi-frontend/core/api';
import type {
    ApiError,
    GetOrderListRequest,
    GetOrderListResponse,
    GetOrderDetailsRequest,
    GetOrderDetailsResponse,
    CustomerOverlayResponse,
} from '@nm-namshi-frontend/services';

export type TOrdersStore = {
    isLoadingOrders: boolean;
    orderList: GetOrderListResponse | null;
    errorOrderList: ApiError | null;
    fetchOrders: (requestBody: GetOrderListRequest) => Promise<void>;

    isLoadingOrderDetails: boolean;
    errorOrderDetails: ApiError | null;
    orderDetails: GetOrderDetailsResponse | null;
    fetchOrderDetails: (requestBody: GetOrderDetailsRequest) => Promise<void>;

    doesUserHaveOrderOnHold: boolean;
    setDoesUserHaveOrderOnHold: (isOnHold: boolean) => void;
};

const useOrdersStore = create<TOrdersStore>((set, get) => ({
    isLoadingOrders: false,
    errorOrderList: null,
    orderList: null,
    isLoadingOrderDetails: false,
    errorOrderDetails: null,
    orderDetails: null,
    doesUserHaveOrderOnHold: false,

    fetchOrders: async (requestBody: GetOrderListRequest) => {
        set({ isLoadingOrders: true });
        try {
            const orderList = await getApiInstance().order.getOrderList({
                requestBody,
            });
            set({ isLoadingOrders: false, orderList });
        } catch (error) {
            set({ orderList: null, isLoadingOrders: false, errorOrderList: error as unknown as ApiError });
        }
    },

    fetchOrderDetails: async (requestBody: GetOrderDetailsRequest) => {
        set({ isLoadingOrderDetails: true });
        try {
            const orderDetails = await getApiInstance().order.getOrderDetails({
                requestBody,
            });
            set({ isLoadingOrderDetails: false, orderDetails });
        } catch (error) {
            set({ orderDetails: null, isLoadingOrderDetails: false, errorOrderDetails: error as unknown as ApiError });
        }
    },

    setDoesUserHaveOrderOnHold: (isOnHold: boolean) => {
        set({ doesUserHaveOrderOnHold: isOnHold });
    },

}));

export default useOrdersStore;
