import React from 'react';

import { clsx } from 'clsx';
import Carousel from 'nuka-carousel/packages/nuka/lib/carousel';

import LanguageSwitch from '@nm-namshi-frontend/core/page_components/shared/LanguageSwitch';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { NavDepartmentsResponse } from '@nm-namshi-frontend/core/types';

import styles from './AppHeaderStrip.module.scss';
import LocationSelector from './LocationSelector';

type TProps = {
    promoMessages: NavDepartmentsResponse['promoMessages'];
};

const getTextStyles = (color: string, fontWeight: string) => ({
    ...(color ? { color } : {}),
    ...(fontWeight ? { fontWeight } : {}),
});

const AppHeaderStrip = ({ promoMessages }: TProps) => {
    const { locale } = useAppPathname();
    const { lang: currentLanguage } = getLocaleConfig(locale);
    const { isArabic } = getLocaleConfig(locale);

    const doPromoMessagesExist = !!promoMessages?.length;

    return (
        <div className={styles.outerContainer}>
            <div className={clsx(styles.innerContainer, 'siteWidthContainer')}>
                <LanguageSwitch theme="dark" />
                {doPromoMessagesExist && (
                    <div className={styles.promoTextContainer}>
                        <Carousel
                            isRtl={isArabic}
                            speed={500}
                            autoplay
                            autoplayInterval={5000}
                            wrapAround
                            renderBottomCenterControls={() => null}
                            renderCenterLeftControls={({ previousSlide }) => (
                                <button type="button" onClick={previousSlide}>
                                    <Icon color="white" name="chevronBack" />
                                </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <button type="button" onClick={nextSlide}>
                                    <Icon color="white" name="chevronForward" />
                                </button>
                            )}
                        >
                            {promoMessages?.map((message, index) => (
                                <div
                                    className={styles.message}
                                    style={getTextStyles(message.color, '400')}
                                    key={`message-${index + 1}`}
                                >
                                    {message.text[currentLanguage]}
                                </div>
                            ))}
                        </Carousel>
                    </div>
                )}
                <LocationSelector />
            </div>
        </div>
    );
};

export default AppHeaderStrip;
