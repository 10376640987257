import React, { useEffect } from 'react';

import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import ProductBox from '@nm-namshi-frontend/core/page_components/shared/product/ProductBox';
import { ProductSimpleResponse, ProductSuggestion } from '@nm-namshi-frontend/services';
import { ProductBoxVariant, TSearchSuggestionsTracking } from '@nm-namshi-frontend/core/types';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './SuggestionItem.module.scss';

type TSuggestionItemProps = {
    title?: string;
    type: 'brand' | 'category' | 'text' | 'products';
    uri?: string;
    product?: ProductSimpleResponse | ProductSuggestion;
    suggestionIndex: number;
    onClick: () => void;
    searchText: string;
};

export const suggestionTypeMapping: Record<string, TSearchSuggestionsTracking> = {
    text: 'Did you Mean',
    category: 'Categories',
    brand: 'Brands',
    products: 'Products',
};

const SuggestionItem: React.FC<TSuggestionItemProps> = ({
    onClick,
    product,
    searchText,
    suggestionIndex,
    title,
    type,
    uri,
}) => {
    const { locale } = useAppPathname();

    useEffect(() => {
        if (product || title) {
            trackEvent({
                event: 'impression_ssuggest',
                searchTerm: searchText,
                suggestionTerm: product && 'title' in product && product.title ? product.title : title || '',
                suggestionType: suggestionTypeMapping[type] || '',
                suggestionPosition: (suggestionIndex + 1).toString(),
            });
        }
    }, []);

    if (product && type === 'products') {
        return (
            <ProductBox
                product={product}
                oneImage
                showWishlistIcon
                showDiscountPercentage
                variant={ProductBoxVariant.SEARCHSUGGESTION}
                onClick={onClick}
                analyticsData={{
                    plpIdx: `0:${suggestionIndex}`,
                    pageType: 'search',
                }}
            />
        );
    }

    if (uri && title) {
        return (
            <AlteredLink locale={locale} href={uri} className={styles.clickableItem} key={title} onClick={onClick}>
                {title}
            </AlteredLink>
        );
    }

    return null;
};

export default SuggestionItem;
