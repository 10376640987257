'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';

import WishlistIcon from '@nm-namshi-frontend/core/page_components/shared/headers/WishlistIcon';
import MiniBag from '@nm-namshi-frontend/core/page_components/shared/headers/MiniBag';
import Fader from '@nm-namshi-frontend/core/page_components/shared/fader/Fader';
import { CatalogType } from '@nm-namshi-frontend/services';
import { getTabHref } from '@nm-namshi-frontend/core/utils/helper';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import Logo from '@nm-namshi-frontend/core/components/Logo';
import { NavDepartmentsResponse } from '@nm-namshi-frontend/core/types';

import SearchBox from './search/SearchBox';
import SearchBarWithSuggestions from './search/SearchBarWithSuggestions';
import styles from './HeaderWithDepartmentTabs.module.scss';
import AccountMenu from './AccountMenu';
import AppHeaderStrip from './AppHeaderStrip';
import SubDepartmentsAndCategories from './SubDepartmentsAndCategories';
import DepartmentNavigationTabs from './DepartmentNavigationTabs';

type TProps = {
    navigationData: NavDepartmentsResponse | { error: string; status: number };
    selectedDepartmentId?: string;
    selectedSubDepartmentId?: string;
    pageType?: CatalogType;
};

const HeaderWithDepartmentTabs = ({
    navigationData,
    pageType,
    selectedDepartmentId,
    selectedSubDepartmentId,
}: TProps) => {
    const [isSearchViewActive, setIsSearchViewActive] = useState(false);
    const [activeSearchText, setActiveSearchText] = useState('');
    const { locale } = useAppContext();

    const setSelectedDepartmentIdInStore = useDepartmentStoreV2((state) => state.setSelectedDepartmentId);
    const setSelectedSubDepartmentIdInStore = useDepartmentStoreV2((state) => state.setSelectedSubDepartmentId);

    useEffect(() => {
        // Update store on receive from server
        if (selectedDepartmentId) {
            setSelectedDepartmentIdInStore(selectedDepartmentId);
        }
    }, [selectedDepartmentId]);
    useEffect(() => {
        // Update store on receive from server
        if (selectedSubDepartmentId) {
            setSelectedSubDepartmentIdInStore(selectedSubDepartmentId);
        }
    }, [selectedSubDepartmentId]);

    const selectedDepartmentTabData = useMemo(() => {
        if ('error' in navigationData || !navigationData?.departments?.length || !selectedDepartmentId) {
            return null;
        }
        return navigationData.departments?.find(({ id }) => id === selectedDepartmentId);
    }, [selectedDepartmentId, navigationData]);

    const doDepartmentsExist = 'departments' in navigationData && navigationData?.departments?.length;

    // Renderers
    const renderAppHeaderStrip = useCallback(() => {
        if ('promoMessages' in navigationData) {
            return <AppHeaderStrip promoMessages={navigationData.promoMessages} />;
        }

        return null;
    }, [navigationData]);

    const tabHref = getTabHref(navigationData as NavDepartmentsResponse, selectedDepartmentId, selectedSubDepartmentId);

    const renderMainContainer = () => (
        <div className={styles.innerContainer}>
            <div className={clsx(styles.innerContents, 'siteWidthContainer')}>
                <div className={styles.headerWithDepartmentsTabContainer}>
                    <AlteredLink locale={locale} href={tabHref} ariaLabel="Namshi" className={styles.logo}>
                        <Logo localized size={23} />
                    </AlteredLink>
                    {doDepartmentsExist && (
                        <DepartmentNavigationTabs
                            departments={navigationData.departments}
                            selectedDepartmentId={selectedDepartmentId}
                            selectedSubDepartmentId={selectedSubDepartmentId}
                        />
                    )}
                </div>

                <div className={styles.actionsContainer}>
                    <SearchBox
                        onClick={() => setIsSearchViewActive(true)}
                        searchBarFlipAnimationKeywords={
                            pageType === 'static'
                                ? selectedDepartmentTabData?.searchBarFlipAnimationKeywords ||
                                  selectedDepartmentTabData?.subDepartments?.find(
                                      (subDep) => subDep.id === selectedSubDepartmentId,
                                  )?.searchBarFlipAnimationKeywords ||
                                  []
                                : []
                        }
                    />
                    <AccountMenu />
                    <div className={styles.iconsContainer}>
                        <WishlistIcon />
                        <MiniBag pageType={pageType} />
                    </div>
                </div>
            </div>
        </div>
    );

    const renderSubDepartmentsAndCategories = () => {
        if (!selectedDepartmentTabData) return null;
        return (
            <SubDepartmentsAndCategories
                selectedDepartmentTabData={selectedDepartmentTabData}
                selectedSubDepartmentId={selectedSubDepartmentId}
            />
        );
    };

    const renderDefaultView = () => (
        <header className={styles.mainContainer}>
            {renderAppHeaderStrip()}
            {renderMainContainer()}
            {renderSubDepartmentsAndCategories()}
        </header>
    );

    const renderSearchAndSuggestionsView = () => (
        <>
            <div
                className={clsx(styles.searchOverlay, isSearchViewActive ? styles.visible : styles.hidden)}
                onClick={() => isSearchViewActive && setIsSearchViewActive(false)}
                aria-hidden="true"
            />
            <Fader
                isActive={isSearchViewActive}
                visibleChildrenClassOverride={clsx(styles.searchViewVisible)}
                invisibleChildrenClassOverride={clsx(styles.searchViewInvisible)}
                timeToExitTransitionOverride={400}
            >
                <SearchBarWithSuggestions
                    isSearchViewActive
                    activeSearchText={activeSearchText}
                    setActiveSearchText={setActiveSearchText}
                    onClose={() => setIsSearchViewActive(false)}
                    selectedDepartmentTabData={selectedDepartmentTabData}
                />
            </Fader>
        </>
    );

    const bodyLock = 'body { overflow: hidden }';
    return (
        <>
            {isSearchViewActive && <style>{bodyLock}</style>}
            {renderDefaultView()}
            {renderSearchAndSuggestionsView()}
        </>
    );
};

export default HeaderWithDepartmentTabs;
