import clsx from 'clsx';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLanguageModifiedLocale, getLocaleConfig, LANGUAGES } from '@nm-namshi-frontend/core/utils/locale';

import styles from './LanguageSwitch.module.scss';

const ARABIC_LANGUAGE_SWITCHER_TEXT = 'العربية';
const ENGLISH_LANGUAGE_SWITCHER_TEXT = 'English';

type TProps = {
    theme: 'light' | 'dark';
    isSingleLanguage?: boolean;
};

const LanguageSwitch = ({ theme, isSingleLanguage = false }: TProps): JSX.Element => {
    const { locale, pathWithLocale } = useAppPathname();
    const { isArabic } = getLocaleConfig(locale);

    const handleClick = (e: React.MouseEvent, newLocale: string) => {
        e.preventDefault();
        window.location.replace(pathWithLocale.replace(locale, newLocale));
    };

    const renderLink = (text: string, newLocale: string, isActive: boolean) => (
        <a
            href={pathWithLocale.replace(locale, newLocale)}
            onClick={(e) => handleClick(e, newLocale)}
            className={clsx(styles.switcher, styles[theme], isActive && styles.active)}
        >
            {text}
        </a>
    );

    return (
        <div className={styles.container}>
            {(!isSingleLanguage || isArabic) &&
                renderLink(ENGLISH_LANGUAGE_SWITCHER_TEXT, getLanguageModifiedLocale(locale, LANGUAGES.en), !isArabic)}

            {!isSingleLanguage && <div className={clsx(styles.divider, styles[theme])}>|</div>}

            {(!isSingleLanguage || !isArabic) &&
                renderLink(ARABIC_LANGUAGE_SWITCHER_TEXT, getLanguageModifiedLocale(locale, LANGUAGES.ar), isArabic)}
        </div>
    );
};

export default LanguageSwitch;
