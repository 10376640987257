import { SessionDetailsResponse } from '@nm-namshi-frontend/services';
import useAuthenticationStore from '@nm-namshi-frontend/core/stores/useAuthenticationStore';
import { hidePageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { convertPrice } from '@nm-namshi-frontend/core/utils/helper';

import useAppPathname from './useAppPathname';

const usePreCheckout = (): ((e: Event, sessionData: SessionDetailsResponse) => void) => {
    const { isNotCleanUser, setIsVerifyEmailOpen } = useAuthenticationStore();
    const { locale } = useAppPathname();
    const { conversionRates } = useConfigContext();
    const { currencyCodeEN } = getLocaleConfig(locale);

    const onCheckout = (e: Event, sessionData: SessionDetailsResponse) => {
        if (!isNotCleanUser && !!sessionData && !!sessionData.items) {
            const convertedTotalAmount = convertPrice({
                conversionRates,
                price: sessionData?.totalAmount,
                fromCurrency: sessionData?.currencyCode,
                toCurrency: currencyCodeEN,
            });

            trackEvent({
                event: 'checkout',
                contents: sessionData?.items?.map(({ brand, normalPrice, parentSku, qty, salePrice, title }) => ({
                    ...(parentSku ? { content_id: parentSku } : {}),
                    content_type: 'product',
                    price: convertPrice({
                        conversionRates,
                        price: salePrice || normalPrice,
                        fromCurrency: sessionData?.currencyCode,
                        toCurrency: currencyCodeEN,
                    }),
                    quantity: qty,
                    content_name: `${brand} ${title}`,
                })),
                value: convertedTotalAmount,
                currency: currencyCodeEN,
            });
            return;
        }

        e.preventDefault();
        setIsVerifyEmailOpen(true);
        hidePageLoader();
    };

    return onCheckout;
};

export default usePreCheckout;
