import React, { useCallback } from 'react';

import clsx from 'clsx';

import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';
import { NavDepartment, NavDepartmentsResponse } from '@nm-namshi-frontend/core/types';

import styles from './DepartmentNavigationTabs.module.scss';

type TProps = {
    departments: NavDepartmentsResponse['departments'];
    selectedDepartmentId?: string;
    selectedSubDepartmentId?: string;
};

const DepartmentNavigationTabs = ({ departments, selectedDepartmentId, selectedSubDepartmentId }: TProps) => {
    const { locale } = useAppPathname();

    const setSelectedDepartmentId = useDepartmentStoreV2((state) => state.setSelectedDepartmentId);
    const setSelectedSubDepartmentId = useDepartmentStoreV2((state) => state.setSelectedSubDepartmentId);
    const selectedDepartmentIdFromStore = useDepartmentStoreV2((state) => state.selectedDepartmentId);

    const selectedId = selectedDepartmentIdFromStore || selectedDepartmentId;

    // Callbacks
    const onDepartmentTabClick = (clickedDepartmentData: NavDepartment) => {
        // Set the current selected department id to a cookie + store
        setSelectedDepartmentId(clickedDepartmentData.id);

        if (clickedDepartmentData.subDepartments?.length) {
            // 1. Get the subdepartment id persisted in store or cookie(ideally both should be in sync)
            const previouslySelectedSubDepartmentIdInCookie = selectedSubDepartmentId;

            if (previouslySelectedSubDepartmentIdInCookie) {
                // 2a. Check if the previous subdepartment id(from cookie or from store) is a part of the current selected department
                const isPreviouslySelectedSubDepartmentIdInCookieExistsInCurrentSelectedDepartment =
                    !!clickedDepartmentData.subDepartments.find(
                        ({ id }) => id === previouslySelectedSubDepartmentIdInCookie,
                    );

                // 2b. Only if not - then update the cookie value and store with the first subdepartment's id(this is the default case)
                if (!isPreviouslySelectedSubDepartmentIdInCookieExistsInCurrentSelectedDepartment) {
                    setSelectedSubDepartmentId(clickedDepartmentData.subDepartments[0].id);
                }
            } else {
                // 2c. If no subdepartment id was persisted before, update the cookie value with the first subdepartment's id(again, default case)
                setSelectedSubDepartmentId(clickedDepartmentData.subDepartments[0].id);
            }
        }
    };

    // Renderers
    const renderMainSection = useCallback(
        () => (
            <>
                {departments.map((departmentData) => {
                    const {
                        id,
                        selectedFillColor,
                        selectedTabIconUri,
                        selectedTabTitleUri,
                        subDepartments,
                        unselectedFillColor,
                        unselectedTabIconUri,
                        unselectedTabTitleUri,
                        uri,
                    } = departmentData;

                    const isTabSelected = selectedId === id;

                    const hasIcon = selectedTabIconUri && unselectedTabIconUri;

                    const tabTitle = isTabSelected ? selectedTabTitleUri : unselectedTabTitleUri;

                    const tabStyles = {
                        backgroundColor: isTabSelected ? selectedFillColor : unselectedFillColor,
                    };

                    // Order of precedence
                    // 1. If subdepartments present for this department + user has previously selected subdepartment -> href = subdepartment matching previous selection
                    // 2. If subdepartments present for this department + user has NOT previously selected subdepartment -> href = from first subdepartment
                    // 3. If not subdepartments present, use the department uri
                    let tabHref;

                    if (subDepartments?.length) {
                        // This is not used in category page, as we will only update categories locally
                        tabHref =
                            subDepartments.find(
                                ({ id: subdepartmentId }) => subdepartmentId === selectedSubDepartmentId,
                            )?.uri ?? subDepartments[0]?.uri;
                    } else {
                        tabHref = uri as string;
                    }

                    return (
                        <div key={`Tab-${id}`} className={styles.tabParent}>
                            <AlteredLink
                                locale={locale}
                                href={tabHref}
                                style={tabStyles}
                                onClick={() => onDepartmentTabClick(departmentData)}
                                className={styles.tab}
                            >
                                {hasIcon && (
                                    <div className={styles.iconsWrapper}>
                                        <img
                                            className={clsx(styles.iconImage, !isTabSelected && styles.active)}
                                            src={unselectedTabIconUri}
                                            alt={`TabIcon-${id}`}
                                        />
                                        <img
                                            className={clsx(
                                                styles.iconImage,
                                                styles.scale,
                                                isTabSelected && styles.active,
                                            )}
                                            src={selectedTabIconUri}
                                            alt={`TabIcon-${id}`}
                                        />
                                    </div>
                                )}
                                <img className={styles.iconTitle} src={tabTitle} alt={`TabTitle-${id}`} width={60} />
                            </AlteredLink>
                        </div>
                    );
                })}
            </>
        ),
        [departments, selectedId],
    );

    return <div className={styles.container}>{renderMainSection()}</div>;
};

export default DepartmentNavigationTabs;
