/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useRouter, useSearchParams } from 'next/navigation';

import { useQuery } from '@tanstack/react-query';
import TextInput from '@nm-namshi-frontend/core/components/forms/TextInput';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { BaseSuggestion } from '@nm-namshi-frontend/services/models/BaseSuggestion';
import { ProductSuggestion } from '@nm-namshi-frontend/services/models/ProductSuggestion';
import { NavDepartment } from '@nm-namshi-frontend/core/types';
import Select from '@nm-namshi-frontend/core/components/forms/Select';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useRecentSearches from '@nm-namshi-frontend/core/hooks/useRecentSearches';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { ProductSimpleResponse, SuggestionRequest } from '@nm-namshi-frontend/services';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import ErrorView from '@nm-namshi-frontend/core/page_components/shared/error/ErrorView';
import { ERRORVIEW } from '@nm-namshi-frontend/core/constants/uiConstants';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { sanitizeText } from '@nm-namshi-frontend/core/utils/helper';
import { DEPARTMENTS_CONFIG, NON_CATALOG_ALL_SUBDEPARTMENT, WIDGET_IDS } from '@nm-namshi-frontend/core/config';
import { getMappedSubDepartmentIdFromConfig } from '@nm-namshi-frontend/core/utils/subdepartments';
import SuggestionItem, { suggestionTypeMapping } from '@nm-namshi-frontend/core/components/SuggestionItem';
import { showPageLoader } from '@nm-namshi-frontend/core/utils/pageLoader';
import RecentlyViewedCarousel from '@nm-namshi-frontend/core/page_components/shared/RecentlyViewedCarousel';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';
import { constructSearchUrl, getDefaultSearchSubdepartment } from '@nm-namshi-frontend/core/utils/catalog';

import styles from './SearchBarWithSuggestions.module.scss';

const SEARCH_STRING_TRIGGER_THRESHOLD = 3; // Minimum characters for triggering a search suggestion API request
const DEBOUNCE_TIME_GAP = 350; // Milliseconds to be waited between each change in search text to trigger suggestions API

type TProps = {
    activeSearchText: string;
    setActiveSearchText: (arg0: string) => void;
    onClose: () => void;
    isSearchViewActive: boolean;
    selectedDepartmentTabData?: NavDepartment | null;
};

const SearchBarWithSuggestions = ({
    activeSearchText,
    isSearchViewActive,
    onClose,
    selectedDepartmentTabData,
    setActiveSearchText,
}: TProps) => {
    const { t } = useTranslation('common');
    const { locale, pushWithLocaleAndPath } = useAppPathname();
    const router = useRouter();
    const debounceRef = useRef(0);
    const { addToRecentSearches, clearAllRecentSearches, deleteFromRecentSearches, recentSearches } =
        useRecentSearches();

    const searchParams = useSearchParams();
    const searchedText = searchParams.get('q');

    const selectedDepartmentIdFromStore = useDepartmentStoreV2((state) => state.selectedDepartmentId);
    const selectedSubDepartmentIdFromStore = useDepartmentStoreV2((state) => state.selectedSubDepartmentId);

    const [searchSubDepartment, setSearchSubDepartment] = useState<string>(
        selectedDepartmentTabData
            ? getDefaultSearchSubdepartment(selectedDepartmentTabData, selectedSubDepartmentIdFromStore)
            : NON_CATALOG_ALL_SUBDEPARTMENT,
    );

    const selectedGender = useMemo(
        () =>
            getMappedSubDepartmentIdFromConfig(
                selectedDepartmentIdFromStore,
                selectedSubDepartmentIdFromStore || searchSubDepartment,
                true,
            ),
        [selectedDepartmentIdFromStore, selectedSubDepartmentIdFromStore, searchSubDepartment],
    );

    const departmentCode = useMemo(
        () =>
            getMappedSubDepartmentIdFromConfig(
                selectedDepartmentIdFromStore,
                searchSubDepartment || selectedSubDepartmentIdFromStore,
                false,
            ),
        [selectedDepartmentIdFromStore, selectedSubDepartmentIdFromStore, searchSubDepartment],
    );

    useEffect(() => {
        if (searchedText) {
            setActiveSearchText(sanitizeText(searchedText));
        }
    }, [isSearchViewActive]);

    const departments = useMemo(() => {
        const deps = DEPARTMENTS_CONFIG.filter(({ isSearchable }) => isSearchable).map(({ code }) => ({
            value: code,
            label: t(`departments.${code}`),
        }));

        return [{ value: NON_CATALOG_ALL_SUBDEPARTMENT, label: t('all') }, ...deps];
    }, []);

    useEffect(() => {
        if (activeSearchText?.length >= SEARCH_STRING_TRIGGER_THRESHOLD) {
            // Adding a debounce logic so that search suggestions is triggered with a slight delay and to avoid calling it at every keypress
            clearTimeout(debounceRef.current);
            debounceRef.current = window.setTimeout(getSearchSuggestions, DEBOUNCE_TIME_GAP);
        }
        return () => clearTimeout(debounceRef.current);
    }, [activeSearchText, searchSubDepartment]);

    const onSuggestionClick = async (title: string, type: string, suggestionPosition: string) => {
        trackEvent({
            event: 'search',
            searchTerm: activeSearchText,
            suggestionTerm: title,
            suggestionType: suggestionTypeMapping[type],
            suggestionPosition,
        });
        if (type === 'text') {
            addToRecentSearches(title);
        }
        onClose();
    };

    const {
        data: searchSuggestions,
        isLoading,
        isPreviousData: isOldSuggestionsShown, // Effectively acts as a "loading" state since we choose to keep previous data(see useQuery params below). On every request made to fetch search suggestions(post debounce), data from the last request(and what is displayed on UI) is marked as stale by RQ and isPreviousData is set to true. Thus, everytime new data comes in, RQ sets isPreviousData to false
        refetch: getSearchSuggestions,
    } = useQuery(
        [REACT_QUERY_KEYS.GET_SEARCH_SUGGESTIONS, `${activeSearchText}-${searchSubDepartment}`],
        () =>
            getApiInstance().product.suggestions({
                requestBody: {
                    q: activeSearchText,
                    selectedTab: selectedDepartmentIdFromStore,
                    ...(departmentCode && { departmentCode }),
                    ...(selectedGender && { selectedGender }),
                } as SuggestionRequest,
            }),
        {
            onSuccess: (data) => {
                const isSearchSuggestionsEmpty =
                    !!data &&
                    isEmpty(data.brands) &&
                    isEmpty(data.categories) &&
                    isEmpty(data.products) &&
                    isEmpty(data.text);
                if (isSearchSuggestionsEmpty) {
                    trackEvent({
                        event: 'noResults',
                        searchTerm: activeSearchText,
                    });
                }
            },
            enabled: false,
            keepPreviousData: true,
        },
    );

    const isSearchSuggestionsEmpty =
        !!searchSuggestions &&
        isEmpty(searchSuggestions.brands) &&
        isEmpty(searchSuggestions.categories) &&
        isEmpty(searchSuggestions.products) &&
        isEmpty(searchSuggestions.text);

    const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape') {
            onClose();
        }
        if (e.key === 'Enter') {
            showPageLoader();

            addToRecentSearches(activeSearchText);
            const constructedSearchUri = constructSearchUrl({
                searchDepartmentId: searchSubDepartment,
                text: activeSearchText,
                selectedGender,
            });

            pushWithLocaleAndPath(constructedSearchUri); // For now, manually adding the code in since BE does not update canonical URL in a right way
            trackEvent({
                event: 'search',
                searchTerm: activeSearchText,
            });
            onClose();
        }
    };

    // Renderers
    const renderClickableResultList = ({
        heading,
        list,
        type,
    }: {
        heading: string;
        list?: Array<BaseSuggestion>;
        type: 'brand' | 'category' | 'text';
    }) => {
        if (!list?.length) return null;

        return (
            <section className={styles.singleListSection}>
                <h4 className={styles.heading}>{heading}</h4>
                {list.map(
                    ({ title, uri }, index) =>
                        !!(title && uri) && (
                            <SuggestionItem
                                title={title}
                                uri={uri}
                                key={title}
                                onClick={() => onSuggestionClick(title, type, String(index + 1))}
                                searchText={activeSearchText}
                                suggestionIndex={index}
                                type={type}
                            />
                        ),
                )}
            </section>
        );
    };

    const renderRecentSearchesList = (heading: string, list?: Array<string>) => (
        <>
            <div className={styles.headingWrapper}>
                <h4 className={styles.heading}>{heading}</h4>
                {list?.length ? (
                    <button type="button" className={styles.cleareAll} onClick={() => clearAllRecentSearches()}>
                        {t('search.clear-all')}
                    </button>
                ) : null}
            </div>
            {list?.map(
                (listItem) =>
                    !!listItem && (
                        <div key={listItem} className={styles.recentSearchListItem}>
                            <AlteredLink
                                locale={locale}
                                href={`/${locale}${constructSearchUrl({
                                    searchDepartmentId: searchSubDepartment,
                                    text: listItem,
                                    selectedGender,
                                })}`}
                                className={styles.recentSearchText}
                                onClick={() => {
                                    trackEvent({
                                        event: 'search',
                                        searchTerm: listItem,
                                    });
                                    onClose();
                                }}
                            >
                                {listItem}
                            </AlteredLink>
                            <button type="button" onClick={() => deleteFromRecentSearches(listItem)}>
                                <Icon name="close" size={20} />
                            </button>
                        </div>
                    ),
            )}
        </>
    );
    const renderProducts = (heading: string, productsList?: Array<ProductSuggestion | ProductSimpleResponse>) => {
        if (!productsList?.length) return null;

        return (
            <section className={styles.productsSection}>
                <h4 className={styles.heading}>{heading}</h4>
                <div className={styles.productBoxes}>
                    {productsList.map((product, index) => (
                        <SuggestionItem
                            key={product.uri}
                            product={product}
                            onClick={() => onSuggestionClick(product.title || '', 'products', String(index + 1))}
                            searchText={activeSearchText}
                            suggestionIndex={index}
                            type="products"
                        />
                    ))}
                </div>
            </section>
        );
    };

    const renderTextualSuggestions = () => {
        if (!searchSuggestions) return null;
        const { brands, categories, text: textSuggestions } = searchSuggestions;

        return (
            <section
                className={clsx(
                    styles.textualSuggestions,
                    isEmpty(textSuggestions) && styles.textualSuggestionsWithNoSpacing,
                )}
            >
                <section className={styles.multipleListsSection}>
                    {renderClickableResultList({
                        heading: t('search.did-you-mean-label'),
                        list: textSuggestions,
                        type: 'text',
                    })}
                    {renderClickableResultList({
                        heading: t('search.brands-label'),
                        list: brands,
                        type: 'brand',
                    })}
                </section>
                {(!!categories?.length || !!brands?.length) && (
                    <section className={styles.multipleListsSection}>
                        {renderClickableResultList({
                            heading: t('search.categories-label'),
                            list: categories,
                            type: 'category',
                        })}
                    </section>
                )}
            </section>
        );
    };

    const renderSuggestionsFromApi = useCallback(() => {
        if (!searchSuggestions) return null;
        const { products } = searchSuggestions;

        return (
            <>
                {renderTextualSuggestions()}
                {renderProducts(t('suggested-items'), products)}
            </>
        );
    }, [activeSearchText, isOldSuggestionsShown, searchSuggestions]);

    const renderDefaultSuggestionsView = useCallback(
        () => (
            <>
                <section className={clsx(styles.recentSearchContainer, styles.singleListSection)}>
                    {!!recentSearches.length &&
                        renderRecentSearchesList(t('search.recent-searches-label'), recentSearches)}
                </section>

                <div className={styles.carouselContainer}>
                    <RecentlyViewedCarousel
                        analyticsData={{
                            pageType: 'search',
                            widgetId: WIDGET_IDS.SEARCH_RECENTLY_VIEWED,
                        }}
                        selectedSubDepartmentIdOverride={searchSubDepartment}
                        onProductBoxClick={onClose}
                    />
                </div>
            </>
        ),
        [recentSearches, searchSubDepartment],
    );

    const renderSearchBar = () => (
        <div className={styles.searchBarContainer}>
            <div className={clsx(styles.innerContainer, 'siteWidthContainer')}>
                {renderDepartmentDropdown()}
                <div className={styles.wideSearchBarContainer}>
                    <TextInput
                        autofocus={isSearchViewActive}
                        displayIcon="search"
                        value={activeSearchText}
                        loading={isLoading || isOldSuggestionsShown}
                        inputClassName={styles.wideSearchBar}
                        onChange={(e) => setActiveSearchText(sanitizeText(e.target.value))}
                        onKeyDown={onKeyDown}
                        onClear={() => setActiveSearchText('')}
                        placeholder={t('searchbar-placeholder')}
                        type="search"
                    />
                </div>
            </div>
        </div>
    );

    const renderSuggestions = () => (
        <div className={clsx(styles.suggestionsContainer)} onClick={onClose}>
            <div className={clsx(styles.suggestionsOuterContent)} onClick={(e) => e.stopPropagation()}>
                {activeSearchText?.length >= SEARCH_STRING_TRIGGER_THRESHOLD && !!searchSuggestions ? (
                    isSearchSuggestionsEmpty ? (
                        <ErrorView
                            actionText={t('back-cta')}
                            type={ERRORVIEW.NO_SEARCH_RESULT}
                            onAction={() => router.back()}
                        />
                    ) : (
                        <div className={clsx(styles.suggestionsInnerContent, 'siteWidthContainer')}>
                            {renderSuggestionsFromApi()}
                        </div>
                    )
                ) : (
                    <div className={clsx(styles.suggestionsInnerContent, 'siteWidthContainer')}>
                        {renderDefaultSuggestionsView()}
                    </div>
                )}
            </div>
        </div>
    );

    const renderDepartmentDropdown = () => (
        <Select
            id="department-code-select"
            placeholder={searchSubDepartment}
            options={departments}
            customSelectClassName={styles.departmentCodeSelect}
            value={searchSubDepartment}
            onChange={setSearchSubDepartment}
            isSearchable={false}
        />
    );

    return (
        <>
            {renderSearchBar()}
            {renderSuggestions()}
        </>
    );
};

export default SearchBarWithSuggestions;
