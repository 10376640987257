/* eslint-disable css-modules/no-unused-class */
import React, { useMemo, useRef, useState } from 'react';

import clsx from 'clsx';
import _ from 'lodash';
import { useRouter } from 'next/navigation';

import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { ROUTES } from '@nm-namshi-frontend/core/constants/uiConstants';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import { slashifyUrl } from '@nm-namshi-frontend/core/utils/url';
import { NavDepartment, NavDepartmentsCategoryDesktop } from '@nm-namshi-frontend/core/types';

import styles from './SubDepartmentsAndCategories.module.scss';
import HomePageBanners from './HomePageBanners';

// The styles for subcategory content is very strictly followed by the catalog content team; it contains details that translate to exact grid-template-areas and grid-gaps
// An example may be found here : https://github.com/fastfishio/catalog-content/blob/live/sivvi/pages/common/_data/2017/desktop-nav/ae/women.json
// This function allows us to get the equivalent inline styles to incorporate them, as managing them via module.scss is not possible
// The following input will generate....
//
// Eg. "itemsGrid": {
//      "gap": "20 100",
//      "areas": ["a b"]
//  }
//
// ...the below styles:
// {
//      grid-template-areas: 'a b c';
//      gap: 20px 100px;
// }
// Note: this has been ported over from V1
const getSubcategoryContentStyles = (gridAreas: Array<string>, gridGaps: string | number) => ({
    ...(gridAreas?.length
        ? {
              gridTemplateAreas: gridAreas.map((item) => `'${item}'`).join(' '),
          }
        : {
              gridAutoFlow: 'column',
          }),
    gap: gridGaps
        ? gridGaps
              .toString()
              .split(' ')
              .map((value) => `${Number(value)}px`)
              .join(' ')
        : '30px',
});

// Some similarly used functions....
const getSingleListSectionStyles = (gridAreaName: string) => ({
    ...(gridAreaName
        ? {
              gridArea: gridAreaName,
          }
        : {}),
});

const getSingleBannerSectionStyles = ({ area, height, width }: { area: string; height?: string; width?: string }) => ({
    ...(area ? { gridArea: area } : {}),
    ...(height ? { maxHeight: height } : {}),
    ...(width ? { maxWidth: width } : {}),
});

const getTitleColorStyle = (color?: string) => ({
    ...(color ? { color } : {}),
});

type TProps = {
    selectedDepartmentTabData: NavDepartment;
    selectedSubDepartmentId?: string;
};

// const MOCK = {
//     url: '/women/new-arrivals/',
//     title: {
//         ar: 'وصلنا حديثاً',
//         en: 'New arrivals',
//     },
//     itemsGrid: {
//         gap: '10 90',
//         areas: ['a b c', '. . .'],
//     },
//     items: [
//         {
//             en: 'Shop by category',
//             ar: 'تسوقي حسب الفئة',
//             url: '/women/new-products/',
//             area: 'a',
//             links: [
//                 {
//                     ar: 'اكسسوارات',
//                     en: 'Accessories',
//                     url: '/women-accessories/new-products/',
//                 },
//                 {
//                     ar: 'شنط',
//                     en: 'Bags',
//                     url: '/women-bags/new-products/',
//                 },
//                 {
//                     ar: 'ركن الجمال',
//                     en: 'Beauty',
//                     url: '/women-beauty-products/new-products/',
//                 },
//                 {
//                     ar: 'ملابس',
//                     en: 'Clothing',
//                     url: '/women-clothing/new-products/',
//                 },
//                 {
//                     ar: 'لوازم منزلية',
//                     en: 'Home',
//                     url: '/women-lifestyle/new-products/',
//                 },
//                 {
//                     ar: 'بريميوم',
//                     en: 'Premium',
//                     url: '/women/premium_shop/?sort[by]=arrival_date&sort[dir]=desc&page=1',
//                 },
//                 {
//                     ar: 'أحذية',
//                     en: 'Shoes',
//                     url: '/women-shoes/new-products/',
//                 },
//                 {
//                     ar: 'المجموعة الرياضية',
//                     en: 'Sports',
//                     url: '/women/sports-collection/?sort[by]=arrival_date&sort[dir]=desc&page=1',
//                 },
//             ],
//         },
//         {
//             en: 'New brands',
//             ar: 'ماركات جديدة',
//             area: 'b',
//             links: [
//                 {
//                     ar: 'اتش اند ام',
//                     en: 'H&M',
//                     url: '/women/h_m/',
//                 },
//                 {
//                     ar: 'ماركس وسبنسر',
//                     en: 'Marks & Spencer',
//                     url: '/women/marks_and_spencer/',
//                 },
//                 {
//                     ar: 'فيكتوريا سيكرت',
//                     en: "Victoria's Secret",
//                     url: '/women/victorias_secret/',
//                 },
//                 {
//                     ar: 'كلوب ال لندن',
//                     en: 'Club L London',
//                     url: '/club_l_london/',
//                 },
//                 {
//                     ar: 'ريفلوشن',
//                     en: 'Revolution',
//                     url: '/revolution/',
//                 },
//                 {
//                     ar: 'كيكو ميلانو',
//                     en: 'KIKO Milano',
//                     url: '/kiko_milano/',
//                 },
//                 {
//                     ar: 'كارل كاني',
//                     en: 'Karl Kani',
//                     url: '/women/karl_kani/',
//                 },
//                 {
//                     ar: 'سويس اربيان',
//                     en: 'Swiss Arabian Perfumes',
//                     url: '/women/swiss_arabian_perfumes/',
//                 },
//                 {
//                     ar: 'ريتوالز',
//                     en: 'Rituals',
//                     url: '/women/rituals/',
//                 },
//             ],
//         },
//         {
//             en: ' ',
//             ar: ' ',
//             area: 'c',
//             links: [
//                 {
//                     ar: 'ماك',
//                     en: 'MAC',
//                     url: '/mac_cosmetics/',
//                 },
//                 {
//                     ar: 'ماكس فاكتور',
//                     en: 'Max Factor',
//                     url: '/women-beauty-products-makeup/max_factor/',
//                 },
//                 {
//                     ar: 'مولتون براون',
//                     en: 'Molton Brown',
//                     url: '/women/molton_brown/',
//                 },
//                 {
//                     ar: 'فيشي',
//                     en: 'Vichy',
//                     url: '/women/vichy/',
//                 },
//                 {
//                     ar: 'هوغو',
//                     en: 'Hugo',
//                     url: '/women/hugo/',
//                 },
//                 {
//                     ar: 'تيفاني اند كو',
//                     en: 'Tiffany & Co.',
//                     url: '/tiffany_co/',
//                 },
//                 {
//                     ar: 'بوهو',
//                     en: 'Boohoo',
//                     url: '/boohoo/',
//                 },
//                 {
//                     ar: 'جوردن',
//                     en: 'Jordan',
//                     url: '/women/jordan/',
//                 },
//             ],
//         },
//     ],
//     bannersGrid: {
//         gap: 15,
//         areas: ['a'],
//     },
//     banners: [
//         {
//             en: 'https://a.nooncdn.com/cms/pages/20230828/d3f0da35865f238b23c0d0bab4614999/New-in-2.jpg',
//             ar: 'https://a.nooncdn.com/cms/pages/20230828/d3f0da35865f238b23c0d0bab4614999/New-in-2.jpg',
//             url: '/women/new-products/',
//             area: 'a',
//             width: 150,
//             title: {
//                 en: 'New In',
//                 ar: 'وصلنا حديثا',
//             },
//             subTitle: {
//                 en: '',
//                 ar: '',
//             },
//         },
//     ],
//     topBrands: [],
// };

const SubDepartmentsAndCategories = ({ selectedDepartmentTabData, selectedSubDepartmentId }: TProps) => {
    const { locale, pathWithoutLocale } = useAppPathname();
    const { lang } = getLocaleConfig(locale);
    const { lang: currentLanguage } = getLocaleConfig(locale);
    const isHomePage = pathWithoutLocale === ROUTES.HOME;

    const [selectedMenuItem, setSelectedMenuItem] = useState<NavDepartmentsCategoryDesktop | null>();
    const [showNavMenu, setShowNavMenu] = useState(false);
    const [isDepModalOpen, setIsDepModalOpen] = useState(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const router = useRouter();

    const doSubDepartmentsExist =
        !!selectedDepartmentTabData.subDepartments && !_.isEmpty(selectedDepartmentTabData.subDepartments);

    const selectedSubdepartmentData = useMemo(() => {
        if (!selectedDepartmentTabData.subDepartments || _.isEmpty(selectedDepartmentTabData.subDepartments)) {
            return null;
        }
        return selectedDepartmentTabData.subDepartments.find(({ id }) => id === selectedSubDepartmentId);
    }, [selectedDepartmentTabData, selectedSubDepartmentId]);

    const categories = useMemo(() => {
        // Since categories can be a part of a L1 Department or L2 SubDepartment,
        // Get the data in either case for easier processing

        // 1. Check if it is present in subDepartmentData
        if (!_.isEmpty(selectedSubdepartmentData?.categories)) {
            return selectedSubdepartmentData?.categories;
        }
        // 2. Check if it is present in departmentData
        if (!_.isEmpty(selectedDepartmentTabData?.categories)) {
            return selectedDepartmentTabData?.categories;
        }
        return [];
    }, [selectedSubdepartmentData, selectedDepartmentTabData]);

    // Callbacks
    const onEnter = (menuItem: NavDepartmentsCategoryDesktop) => {
        setSelectedMenuItem(menuItem);

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (showNavMenu) return;

        timeoutRef.current = setTimeout(() => {
            setShowNavMenu(!!menuItem?.items?.length);
        }, 150);
    };

    const onLeave = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        setShowNavMenu(false);
        setSelectedMenuItem(null);
    };

    const handleTrackClick = (category: { imagePath?: string; url: string; assetId?: string }) => {
        trackEvent({
            event: 'contentClick',
            contentClickUrl: category.url,
            imagePath: category.imagePath,
            contentCreative: category?.assetId,
            contentModuleType: 'link',
        });
    };

    // Renderers
    const renderSubDepartments = () => {
        if (!doSubDepartmentsExist) {
            return null;
        }

        return (
            <>
                <Modal
                    open={isDepModalOpen}
                    onClose={() => setIsDepModalOpen(false)}
                    containerClass={styles.modalContent}
                >
                    <HomePageBanners
                        locale={locale}
                        onSelect={() => setIsDepModalOpen(false)}
                        selectedDepartmentData={selectedDepartmentTabData}
                    />
                </Modal>
                <div className={styles.subDepartmentCtaWrapper}>
                    <button type="button" className={styles.subDepartmentCta} onClick={() => setIsDepModalOpen(true)}>
                        <div className={styles.subDepartmentLabel}>{selectedSubdepartmentData?.title}</div>
                        <Icon name="chevronDownBold" size={14} />
                    </button>
                </div>
            </>
        );
    };

    const renderCategories = () => {
        if (isHomePage || !categories || _.isEmpty(categories)) return null;

        return (
            <nav className={styles.container}>
                <div className={clsx(styles.innerContainer)}>
                    {showNavMenu && <div className={styles.backdrop} />}
                    <div className={styles.categoriesContainer} onMouseLeave={onLeave}>
                        <div className={clsx('siteWidthContainer', styles.menuBar)}>
                            {renderSubDepartments()}
                            <div className={styles.menuBarContent}>
                                {categories?.map((item, idx) => {
                                    if ('title' in item && 'url' in item) {
                                        return (
                                            <AlteredLink
                                                locale={locale}
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={`Sub-${item.title}-${item.url}-${idx}`}
                                                href={`/${locale}${item.url}`}
                                                className={clsx(
                                                    styles.title,
                                                    selectedMenuItem?.url === item.url && styles.active,
                                                )}
                                                onMouseEnter={() => {
                                                    onEnter(item);
                                                }}
                                                style={getTitleColorStyle(item.color)}
                                                onClick={() => {
                                                    if (
                                                        slashifyUrl(item.url, true) ===
                                                        slashifyUrl(ROUTES.BRANDS, true, true)
                                                    ) {
                                                        // Triggering a manual refresh of router to force /brands pages to reload everytime
                                                        // NextJS prefetches pages that can result in stale page content
                                                        // Refreshing the router ensures fresh content especially since the page depends on cookies(for selectedDepartmentId are considered)
                                                        router.refresh();
                                                    }
                                                    handleTrackClick({ url: item.url });
                                                }}
                                            >
                                                {item.title[currentLanguage]}
                                                <svg
                                                    width="32"
                                                    height="50"
                                                    viewBox="0 0 32 50"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M0 0H16V20.8755C16 23.59 16.6139 26.2693 17.7958 28.713L18.2678 29.6887C20.8104 34.9456 24.4467 39.5988 28.9332 43.3367L31.8352 45.7545V50H0V0Z"
                                                        fill="#242424"
                                                        fillOpacity="0"
                                                    />
                                                </svg>
                                            </AlteredLink>
                                        );
                                    }

                                    return null;
                                })}
                            </div>
                        </div>
                        {showNavMenu && (
                            <div className={styles.menuContainer}>
                                <section className={clsx(styles.contentsContainer)}>
                                    {/* Section for clickable subcategory items */}
                                    <div className={clsx('siteWidthContainer', styles.contents)}>
                                        {!!selectedMenuItem?.items?.length && (
                                            <div
                                                style={getSubcategoryContentStyles(
                                                    selectedMenuItem?.itemsGrid?.areas,
                                                    selectedMenuItem?.itemsGrid?.gap,
                                                )}
                                                className={clsx(styles.contentGrid, styles.alignStart)}
                                            >
                                                {selectedMenuItem?.items.map((section, ix) => (
                                                    <div
                                                        key={`${selectedMenuItem}-${section.en ?? section.area}-${
                                                            ix + 1
                                                        }`}
                                                        style={getSingleListSectionStyles(section.area)}
                                                        className={styles.contentColumn}
                                                    >
                                                        <div className={styles.contentColumnTitleContainer}>
                                                            {section[lang] && (
                                                                <h4 className={styles.contentColumnTitle}>
                                                                    {section[lang]}
                                                                </h4>
                                                            )}
                                                        </div>
                                                        <div className={styles.contentColumnLinks}>
                                                            {/* BE response is not typed well and so we stick with type: any for now */}
                                                            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                                            {section.links?.map((linkItem: any, idx: number) => (
                                                                <AlteredLink
                                                                    locale={locale}
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    key={`Link-to-${linkItem.en}-${idx}`}
                                                                    href={`/${locale}${linkItem.url}`}
                                                                    className={styles.contentLink}
                                                                    onClick={() => {
                                                                        handleTrackClick({ url: linkItem.url });
                                                                    }}
                                                                >
                                                                    {linkItem[lang]}
                                                                </AlteredLink>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {/* Section for clickable subcategory banners */}
                                        {!!selectedMenuItem?.banners?.length && (
                                            <div
                                                style={getSubcategoryContentStyles(
                                                    selectedMenuItem?.bannersGrid?.areas,
                                                    selectedMenuItem?.bannersGrid?.gap,
                                                )}
                                                className={clsx(styles.contentGrid)}
                                            >
                                                {selectedMenuItem?.banners.map((bannerSection, index) => (
                                                    <div
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        key={`${selectedMenuItem}-${
                                                            bannerSection.en ?? bannerSection.area
                                                        }-${index}`}
                                                        style={getSingleBannerSectionStyles({
                                                            area: bannerSection?.area,
                                                            ...(bannerSection?.height
                                                                ? {
                                                                      height: bannerSection?.height.toString(),
                                                                  }
                                                                : {}),
                                                            ...(bannerSection?.width
                                                                ? {
                                                                      width: bannerSection?.width.toString(),
                                                                  }
                                                                : {}),
                                                        })}
                                                        className={styles.contentColumn}
                                                    >
                                                        <AlteredLink
                                                            locale={locale}
                                                            href={bannerSection.url}
                                                            className={styles.bannerContent}
                                                            onClick={() =>
                                                                handleTrackClick({
                                                                    url: bannerSection.url,
                                                                    imagePath: bannerSection.en,
                                                                    ...(bannerSection?.assetId
                                                                        ? {
                                                                              assetId:
                                                                                  bannerSection?.assetId.toString(),
                                                                          }
                                                                        : {}),
                                                                })
                                                            }
                                                        >
                                                            <img
                                                                src={bannerSection[lang]}
                                                                alt={bannerSection[lang]}
                                                                width={bannerSection?.width}
                                                                height={bannerSection?.width}
                                                                {...(bannerSection?.assetId
                                                                    ? {
                                                                          id: bannerSection?.assetId.toString(),
                                                                      }
                                                                    : {})}
                                                            />
                                                            <div>
                                                                {bannerSection?.title?.[lang] && (
                                                                    <h3>{bannerSection.title[lang]}</h3>
                                                                )}
                                                                {bannerSection?.subTitle?.[lang] && (
                                                                    <h4>{bannerSection.subTitle[lang]}</h4>
                                                                )}
                                                            </div>
                                                        </AlteredLink>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </section>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        );
    };

    if (!doSubDepartmentsExist && _.isEmpty(categories)) return null;

    return renderCategories();
};

export default SubDepartmentsAndCategories;
