import React from 'react';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import { ROUTES } from '@nm-namshi-frontend/core/constants/uiConstants';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import Badge from '@nm-namshi-frontend/core/components/Badge';
import useWishlistSkus from '@nm-namshi-frontend/core/hooks/useWishlistSkus';

import styles from './WishlistIcon.module.scss';
import AlteredLink from '../AlteredLink';

type TProps = {
    href?: string;
};

const WishlistIcon = ({ href }: TProps) => {
    const { locale } = useAppPathname();

    const { wishlistParentSkus } = useWishlistSkus();

    const doesWishlistHaveItems = wishlistParentSkus.length > 0;
    return (
        <AlteredLink locale={locale} href={href || ROUTES.WISHLIST} className={styles.container}>
            <Icon name="heart" size={24} />
            <Badge show={doesWishlistHaveItems}>{wishlistParentSkus.length}</Badge>
        </AlteredLink>
    );
};
export default WishlistIcon;
