import React from 'react';

import { useTranslation } from 'react-i18next';
import { TypeAnimation } from 'react-type-animation';

import Icon from '@nm-namshi-frontend/core/components/Icon';
import useParamState from '@nm-namshi-frontend/core/hooks/useParamState';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';

import styles from './SearchBox.module.scss';

type TProps = {
    onClick?: () => void;
    searchBarFlipAnimationKeywords: string[];
};

const SearchBox = ({ onClick, searchBarFlipAnimationKeywords }: TProps) => {
    const { t } = useTranslation('common');
    const { locale } = useAppPathname();
    const [searchParam, , getParamUrl] = useParamState('q');

    const renderAnimatedSuggestions = () => (
        <TypeAnimation
            sequence={searchBarFlipAnimationKeywords.flatMap((keyword) => [keyword, 2000])}
            wrapper="span"
            speed={1}
            cursor
            repeat={Infinity}
            preRenderFirstString
            omitDeletionAnimation
        />
    );

    return (
        <button type="button" className={styles.container} onClick={onClick}>
            <div className={styles.searchBox}>
                <Icon name="search" size={20} alt="Search Icon" />
                {searchParam ? (
                    <span className={styles.searchText}>{searchParam}</span>
                ) : (
                    <span className={styles.placeholder}>
                        {searchBarFlipAnimationKeywords.length > 0 ? (
                            <>
                                {t('shared.search-for')} {renderAnimatedSuggestions()}
                            </>
                        ) : (
                            t('shared.search')
                        )}
                    </span>
                )}
            </div>
            {searchParam && (
                <AlteredLink
                    locale={locale}
                    href={getParamUrl('')}
                    className={styles.clear}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Icon name="close" size={14} />
                </AlteredLink>
            )}
        </button>
    );
};

export default SearchBox;
