'use client';

import React from 'react';

import _ from 'lodash';

import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import { DEPARTMENTS_CONFIG } from '@nm-namshi-frontend/core/config';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { TLocaleSEO, NavDepartment, NavSubdepartment } from '@nm-namshi-frontend/core/types';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { getWidgetImageUrl } from '@nm-namshi-frontend/core/utils/images';
import useDepartmentStoreV2 from '@nm-namshi-frontend/core/stores/useDepartmentStoreV2';

import styles from './HomePageBanners.module.scss';

type TProps = {
    locale: TLocaleSEO;
    onSelect?: () => void;
    selectedDepartmentData?: NavDepartment; // If present, this component will display its subDepartments
};

const HomePageBanners = ({ locale, onSelect, selectedDepartmentData }: TProps) => {
    const { lang, seoRegion } = getLocaleConfig(locale);

    const setSelectedDepartmentId = useDepartmentStoreV2((state) => state.setSelectedDepartmentId);
    const setSelectedSubDepartmentId = useDepartmentStoreV2((state) => state.setSelectedSubDepartmentId);

    // Callbacks
    const onBannerClick = ({ departmentId, subDepartmentId }: { departmentId: string; subDepartmentId: string }) => {
        onSelect?.();

        setSelectedDepartmentId(departmentId);
        setSelectedSubDepartmentId(subDepartmentId);

        trackEvent({
            event: 'feed_selection',
            pageType: 'home',
            feedL1: departmentId,
            feedL2: subDepartmentId,
        });
    };

    // Renderer
    const renderSubDepartmentBannerFromNavigationCategories = (subDepartmentData: NavSubdepartment) => {
        // To render subDepartments, there must always be selectedDepartmentData
        if (!selectedDepartmentData || _.isEmpty(selectedDepartmentData)) return null;

        return (
            <div className={styles.banner} key={subDepartmentData.imageUri}>
                <AlteredLink
                    locale={locale}
                    href={subDepartmentData.uri}
                    passHref
                    onClick={() => {
                        onBannerClick({
                            departmentId: selectedDepartmentData.id,
                            subDepartmentId: subDepartmentData.id,
                        });
                    }}
                >
                    <img
                        src={getWidgetImageUrl(subDepartmentData?.imageUri)}
                        alt={subDepartmentData.title}
                        className={styles.bannerImage}
                    />
                    <div className={styles.bannerText}>
                        <div className={styles.bannerTitle}>{subDepartmentData.title}</div>
                    </div>
                </AlteredLink>
            </div>
        );
    };

    // Render from FE config (TO BE DEPRECATED)
    const renderBannerFromFEConfig = (banner: (typeof DEPARTMENTS_CONFIG)[number]) => (
        <div className={styles.banner} key={banner?.imageUrl.en}>
            <AlteredLink
                locale={locale}
                href={banner.linkUrlV2}
                passHref
                onClick={() => {
                    onBannerClick({
                        departmentId: banner.departmentTabId,
                        subDepartmentId: banner.subDepartmentId,
                    });
                }}
            >
                <img
                    src={getWidgetImageUrl(banner?.imageUrl?.[lang])}
                    alt={banner.linkUrl}
                    className={styles.bannerImage}
                    width={894}
                    height={1288}
                />
                <div className={styles.bannerText}>
                    <div className={styles.bannerTitle}>{banner.linkText[lang]}</div>
                </div>
            </AlteredLink>
        </div>
    );

    // From subDepartments within selectedDepartmentData from navigation-categories API
    if (selectedDepartmentData && !!selectedDepartmentData.subDepartments?.length) {
        const { subDepartments } = selectedDepartmentData;

        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    {subDepartments.map((subDepartment) =>
                        renderSubDepartmentBannerFromNavigationCategories(subDepartment),
                    )}
                </div>
            </div>
        );
    }

    // Again, from FE config (TO BE DEPRECATED)
    const banners = DEPARTMENTS_CONFIG.filter(
        (dept) => dept.showOnHomeBanner && dept.enabledForCountries.includes(seoRegion),
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>{banners.map((banner) => renderBannerFromFEConfig(banner))}</div>
        </div>
    );
};

export default HomePageBanners;
