import React from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { SessionItemResponse } from '@nm-namshi-frontend/services';
import ProductImage from '@nm-namshi-frontend/core/page_components/shared/product/ProductImage';
import { IMAGE_SIZE } from '@nm-namshi-frontend/core/utils/images';
import ProductPrice from '@nm-namshi-frontend/core/page_components/shared/product/ProductPrice';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import DeliveryEstimateTag from '@nm-namshi-frontend/core/components/DeliveryEstimateTag';

import styles from './MiniBagItem.module.scss';

type TProps = {
    item: SessionItemResponse;
};

const MiniBagItem = ({ item }: TProps) => {
    const { locale } = useAppPathname();
    const { t } = useTranslation('common');

    const { brand, discountPercent, groupValue, imageKey, normalPrice, qty, salePrice, title, uri, variantValue } =
        item;

    // Renderers
    const BrandAndTitle = () => {
        if (!(brand || title)) return null;

        return (
            <section className={clsx(styles.detailsSection, styles.column)}>
                {!!brand && <b>{brand}</b>}
                {!!title && <span className={styles.title}>{title}</span>}
            </section>
        );
    };

    const Price = () => {
        if (!salePrice) return null;

        return (
            <div className={styles.priceContainer}>
                <ProductPrice price={normalPrice} salePrice={salePrice} discountPercent={discountPercent} />
            </div>
        );
    };

    const GroupAndVariantAndQty = () => {
        if (!(groupValue || variantValue || qty)) return null;
        const hideSizeVariant = item.variantValue?.toLowerCase() === 'os';

        return (
            <section className={styles.variantValues}>
                {'qty' in item && !!item.qty && (
                    <div className={clsx(styles.qty, styles.variant)}>
                        <span>{t('qty')}:</span>
                        <strong>{item.qty}</strong>
                    </div>
                )}
                {item.variantValue && !hideSizeVariant && (
                    <div className={clsx(styles.sizeWrapper, styles.variant)}>
                        <span>{t('item-size')}:</span>
                        <strong className={styles.variant}>{item.variantValue}</strong>
                    </div>
                )}
            </section>
        );
    };

    return (
        <div className={styles.container}>
            <AlteredLink className={styles.productImage} href={uri} locale={locale}>
                <ProductImage imageKey={imageKey} size={IMAGE_SIZE.MS} altText={title || ''} />
            </AlteredLink>
            <section className={styles.detailsContainer}>
                <BrandAndTitle />
                <Price />
                <div className={styles.bottomStick}>
                    <GroupAndVariantAndQty />

                    <DeliveryEstimateTag estimate={item.estimatedDelivery} />
                </div>
            </section>
        </div>
    );
};

export default MiniBagItem;
