import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';

import { ProgressiveDiscountTracker } from '@nm-namshi-frontend/services/models/ProgressiveDiscountTracker';

import './customStyle.css';
import styles from './ProgressiveDiscountBar.module.scss';
import { ANIMATION_INTERVAL, PROGRESS_ANIMATE_PRECENTAGE, SMALL_ROUNDING_VALUE } from './constants';

type TProps = {
    discountTracker: ProgressiveDiscountTracker;
};

const ProgressiveDiscountBar = ({ discountTracker }: TProps) => {
    const intervalRef = useRef<NodeJS.Timeout>();
    const [progress, setProgress] = useState(0);

    const fillProgress = (precentage: number, direction: 1 | -1) => {
        intervalRef.current = setInterval(() => {
            setProgress((prev) => {
                if (direction * prev > direction * precentage) {
                    clearInterval(intervalRef.current);
                    return precentage;
                }
                return prev + PROGRESS_ANIMATE_PRECENTAGE * direction;
            });
        }, ANIMATION_INTERVAL);
    };

    useEffect(() => {
        if (progress !== discountTracker.percentage) {
            clearInterval(intervalRef.current); 
            fillProgress(discountTracker.percentage, discountTracker.percentage < progress ? -1 : 1);
        }
        return () => clearInterval(intervalRef.current);
    }, [discountTracker]);

    if (!discountTracker) return null;

    return (
        <ProgressBar
            unfilledBackground="var(--clr-brand-l2)"
            hasStepZero={false}
            height={5}
            percent={progress + SMALL_ROUNDING_VALUE}
            filledBackground="var(--clr-brand-d2)"
        >
            {discountTracker.thresholds.map((step) => (
                <Step key={step.title}>
                    {({ accomplished }) => (
                        <div className={styles.stepContainer}>
                            <div
                                className={clsx(styles.stepBox, {
                                    [styles.fulfilled]: step.isUnlocked && accomplished,
                                })}
                            >
                                {step.title}
                            </div>
                        </div>
                    )}
                </Step>
            ))}
        </ProgressBar>
    );
};

export default ProgressiveDiscountBar;
