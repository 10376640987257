import React, { useEffect, useMemo, useRef } from 'react';

import parse from 'html-react-parser';
import clsx from 'clsx';

import { CatalogType } from '@nm-namshi-frontend/services';
import { ProgressiveDiscountTracker } from '@nm-namshi-frontend/services/models/ProgressiveDiscountTracker';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import Button from '@nm-namshi-frontend/core/components/Button';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useIsInViewport from '@nm-namshi-frontend/core/hooks/useIsInViewport';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';

import ProgressiveDiscountBar from './ProgressiveDiscountBar';
import styles from './ProgressiveDiscountWidget.module.scss';
import AlteredLink from '../AlteredLink';
import { COUPON_PIPE } from './constants';

type TProps = {
    discountTracker: ProgressiveDiscountTracker;
    onClick?: () => void;
    pageType?: CatalogType;
};
const IS_MOBILE = isSchmatalog();

const ProgressiveDiscountWidget = ({ discountTracker, onClick, pageType }: TProps) => {
    const { locale } = useAppPathname();
    const ref = useRef<HTMLDivElement>(null);
    const { pathWithoutLocale } = useAppPathname();
    const { isInView } = useIsInViewport({ ref });
    const { cta, description, filterKeyCode, titles } = discountTracker;

    const title = useMemo(() => {
        const firstTitle = titles[0];
        if (firstTitle.includes(COUPON_PIPE)) {
            const titleWithoutPipe = firstTitle.replace(COUPON_PIPE, ' ');
            return (
                <div>
                    {parse(titleWithoutPipe)}
                    <span className={styles.coupon}>{discountTracker.couponCode}</span>
                </div>
            );
        }
        return parse(firstTitle);
    }, [titles]);

    useEffect(() => {
        // This event will be fired only in details
        if (isInView && (IS_MOBILE || pageType === 'details')) {
            const { nextThresholdRemaining, thresholds } = discountTracker;
            const steps = thresholds.length;
            const current_step = thresholds.reduce(
                (unluckedSteps, step) => (step.isUnlocked ? unluckedSteps + 1 : unluckedSteps),
                0,
            );
            trackEvent({
                event: 'couponProgressImpression',
                steps,
                current_step,
                next_step: nextThresholdRemaining || 0,
                pagePath: pathWithoutLocale,
                event_location: 'pdp',
            });
        }
    }, [isInView]);

    const ctaClicked = () => {
        trackEvent({
            event: 'couponContentClick',
            action: 'add_more',
            url: filterKeyCode,
            pagePath: pathWithoutLocale,
            event_location: 'pdp',
        });
        onClick?.();
    };
    return (
        <div ref={ref} className={clsx(styles.container)}>
            <div className={styles.title}>
                <Icon name="couponTag" size={20} />
                <span>{title}</span>
            </div>

            <ProgressiveDiscountBar discountTracker={discountTracker} />

            <div className={styles.footer}>
                {!!description && <div className={styles.description}>{parse(description)}</div>}
                {filterKeyCode && (
                    <AlteredLink
                        onClick={ctaClicked}
                        className={styles.buttonWrapper}
                        locale={locale}
                        href={filterKeyCode}
                    >
                        <Button size="small" variant="secondary">
                            {cta}
                            <Icon name="chevronForwardBold" />
                        </Button>
                    </AlteredLink>
                )}
            </div>
        </div>
    );
};
export default ProgressiveDiscountWidget;
