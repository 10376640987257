import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import useUpdateAddressKey from '@nm-namshi-frontend/core/hooks/useUpdateAddressKey';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { clearGeoLocCookies } from '@nm-namshi-frontend/core/utils/geoLocCookies';

const useDoSignout = ({ onSuccess }: { onSuccess?: (response: any) => void }) => {
    const { t } = useTranslation('account');
    const { toggleShowFullScreenLoader } = useLayoutStore();

    const { updateAddressKey } = useUpdateAddressKey();

    const { mutate: doSignout } = useMutation(
        [REACT_QUERY_KEYS.SIGNOUT],
        async () => {
            toggleShowFullScreenLoader(true);
            await getApiInstance().noonAuth.noonSignout({
                requestBody: {},
            });
            await getApiInstance().namshiAuth.namshiSignout({});
        },
        {
            onSuccess: (response) => {
                updateAddressKey('');
                clearGeoLocCookies();

                trackEvent({
                    event: 'logout',
                });

                onSuccess?.(response);
            },
            onError: (err: any) => {
                toast.error(
                    err.body.error || err.body?.message || err.body.userMessage || t('authentication.sign-out-error'),
                );
            },

            onSettled: () => {
                toggleShowFullScreenLoader(false);
            },
        },
    );

    return doSignout;
};

export default useDoSignout;
