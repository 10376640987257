'use client';

import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ACCOUNT_NAV_CONFIG, NAMSHI_ACCOUNT_PAGE_FALLBACK_URL } from '@nm-namshi-frontend/core/config';
import Fader from '@nm-namshi-frontend/core/page_components/shared/fader/Fader';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { ClientApp } from '@nm-namshi-frontend/core/types';
import { getExternalHref } from '@nm-namshi-frontend/core/utils/url';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { setCustomerDataForAnalytics, trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { TAccountEvents } from '@nm-namshi-frontend/core/utils/analytics.types';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import useAuthenticationStore from '@nm-namshi-frontend/core/stores/useAuthenticationStore';
import useDoSignout from '@nm-namshi-frontend/core/hooks/useDoSignout';
import Loader from '@nm-namshi-frontend/core/components/loaders/Loader';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';
import { getHelpCenterLink } from '@nm-namshi-frontend/core/page_components/shared/footer/LinksGroups';
import useOrdersStore from '@nm-namshi-frontend/core/stores/useOrdersStore';

import styles from './AccountMenu.module.scss';

const AccountMenu = () => {
    const { t } = useTranslation('common');
    const { locale } = useAppPathname();
    const { setAuthModal } = useLayoutStore();
    const doesUserHaveOrderOnHold = useOrdersStore((store) => store.doesUserHaveOrderOnHold);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const customerData = useCustomerStore((store) => store.customerData);
    const isLoadingCustomerData = useCustomerStore((store) => store.isLoadingCustomerData);
    const { isNotCleanUser } = useAuthenticationStore();

    useEffect(() => {
        if (customerData?.data?.id) {
            setCustomerDataForAnalytics(customerData);
        }
    }, [customerData]);

    const doSignout = useDoSignout({
        onSuccess: () => {
            window.location.reload();
        },
    });

    // Callbacks
    const getAccountPageUrl = (path = '') => {
        const accountPageUrl = getExternalHref(ClientApp.BIGALOG, ClientApp.ACCOUNT, locale, path);

        if (!accountPageUrl) {
            // Open the fallback url persisted on UI config
            return `${NAMSHI_ACCOUNT_PAGE_FALLBACK_URL}${locale}${path}`;
        }

        return accountPageUrl;
    };

    const handleAccountMenuItemOnClick = (code: (typeof ACCOUNT_NAV_CONFIG)[number]['code']) => {
        if (code) {
            const eventString = `page_${code}` as TAccountEvents;

            trackEvent({
                event: eventString,
            });
        }
    };

    const handleSignOutOnClick = () => {
        setIsMenuOpen(false);
        doSignout();
    };

    const renderContent = (code: string, titleKey: string) => (
        <>
            {code === 'orders' && doesUserHaveOrderOnHold && <Icon name="errorCircle" size={18} color="red" />}
            {t(`my-account-menu.${titleKey}`)}
        </>
    );

    const MenuOptions = useCallback(
        () => (
            <>
                {ACCOUNT_NAV_CONFIG?.map(({ code, path, titleKey }) => (
                    <AlteredLink
                        locale={locale}
                        onClick={() => {
                            handleAccountMenuItemOnClick(code);
                        }}
                        key={`AccountMenuItem-${code}`}
                        href={getAccountPageUrl(path[0])}
                        className={styles.menuItemCta}
                    >
                        {renderContent(code, titleKey)}
                    </AlteredLink>
                ))}
            </>
        ),
        [doesUserHaveOrderOnHold],
    );

    const HelpButton = () => (
        <a className={styles.menuItemCta} target="_blank" href={getHelpCenterLink(locale, 'home')}>
            {t('my-account-menu.nav.help-center')}
        </a>
    );

    const SignoutButton = () => (
        <button type="button" className={clsx(styles.signoutCta, styles.menuItemCta)} onClick={handleSignOutOnClick}>
            {t('my-account-menu.sign-out-cta')}
        </button>
    );
    const firstName = customerData?.data?.firstName;

    const handleClick = () => {
        if (customerData) {
            setIsMenuOpen(!isMenuOpen);
        } else {
            setAuthModal({ show: true, enableSignupJourney: true });
        }
    };

    const LoggedInView = () => (
        <>
            <div className={styles.textContent}>
                {firstName && (
                    <span className={clsx(styles.subdued, styles.name)}>
                        {t('my-account-menu._greeting-label', { firstName })}
                    </span>
                )}
                <h4 className={styles.emphasized}>{t('my-account-menu.my-account-label')}</h4>
            </div>
            {doesUserHaveOrderOnHold && <Icon name="errorCircle" size={18} color="red" />}
            <Icon
                name="chevronDownBold"
                size={12}
                className={clsx(isMenuOpen ? styles.triggerIconRotated : styles.triggerIcon)}
            />
        </>
    );

    const LoggedOutView = () => (
        <div className={clsx(styles.textContent, styles.loggedOutView)}>
            <span>{t('my-account-menu.sign-in-cta')}</span>
            <Icon name="person" />
        </div>
    );

    // logout the user from old namshi session
    const V1LogoutButton = () => (
        <button type="button" className={styles.triggerContainer} onClick={() => doSignout()}>
            <div className={clsx(styles.textContent, styles.loggedOutView)}>
                <span>{t('my-account-menu.sign-out-cta')}</span>
                <Icon name="person" />
            </div>
        </button>
    );

    const AccountMenuTrigger = useCallback(
        () =>
            isNotCleanUser ? (
                <V1LogoutButton />
            ) : (
                <button type="button" className={styles.triggerContainer} onClick={handleClick}>
                    {customerData ? <LoggedInView /> : <LoggedOutView />}
                </button>
            ),
        [customerData, isMenuOpen, isNotCleanUser, doesUserHaveOrderOnHold],
    );

    const AccountMenuDropdown = () => (
        <>
            <div
                className={clsx(styles.dropdownOverlay, isMenuOpen ? styles.visible : styles.hidden)}
                onClick={() => isMenuOpen && setIsMenuOpen(false)}
                aria-hidden="true"
            />
            <Fader isActive={isMenuOpen} containerClass={styles.dropdownContainer}>
                <div className={styles.dropdownItems}>
                    <MenuOptions />
                    <HelpButton />
                    <SignoutButton />
                </div>
            </Fader>
        </>
    );

    if (isLoadingCustomerData) {
        return (
            <span className={clsx(styles.accountMenuContainer, styles.isLoading)}>
                <Loader color="var(--clr-dark)" />
            </span>
        );
    }

    if (!customerData) {
        return (
            <div className={clsx(styles.accountMenuContainer, styles.endJustified)}>
                {isNotCleanUser ? (
                    <V1LogoutButton />
                ) : (
                    <button type="button" className={styles.triggerContainer} onClick={handleClick}>
                        <LoggedOutView />
                    </button>
                )}
            </div>
        );
    }
    return (
        <div className={clsx(styles.accountMenuContainer, styles.endJustified)}>
            <AccountMenuTrigger />
            <AccountMenuDropdown />
        </div>
    );
};

export default AccountMenu;
