import { useEffect, useState } from 'react';

import { addRecentSearch, deleteRecentSearch, getRecentSearches, clearRecentSearches } from '../utils/catalog';

type TReturnType = {
    addToRecentSearches: (arg0: string) => void;
    deleteFromRecentSearches: (arg0: string) => void;
    clearAllRecentSearches: () => void;
    recentSearches: Array<string>;
};

// By default, this hook fetches 4 products' data(maximum possible to be shown in search suggestions)
const useRecentSearches = (): TReturnType => {
    const [recentSearches, setRecentSearches] = useState<string[]>(['']);

    useEffect(() => {
        setRecentSearches(getRecentSearches());
    }, []);

    // Callbacks
    const addToRecentSearches = (text: string) => {
        addRecentSearch(text);
        setRecentSearches(getRecentSearches());
    };

    const deleteFromRecentSearches = (text: string) => {
        deleteRecentSearch(text);
        setRecentSearches(getRecentSearches());
    };

    const clearAllRecentSearches = () => {
        clearRecentSearches();
        setRecentSearches(getRecentSearches());
    };

    return {
        addToRecentSearches,
        deleteFromRecentSearches,
        clearAllRecentSearches,
        recentSearches,
    };
};

export default useRecentSearches;
