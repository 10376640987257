import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import useOnclickOutside from 'react-cool-onclickoutside';
import { usePathname } from 'next/navigation';

import { useQuery } from '@tanstack/react-query';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { CatalogType, SessionDetailsResponse } from '@nm-namshi-frontend/services';
import Button from '@nm-namshi-frontend/core/components/Button';
import ProductPrice from '@nm-namshi-frontend/core/page_components/shared/product/ProductPrice';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { ROUTES } from '@nm-namshi-frontend/core/constants/uiConstants';
import AlteredLink from '@nm-namshi-frontend/core/page_components/shared/AlteredLink';
import usePreCheckout from '@nm-namshi-frontend/core/hooks/usePreCheckout';
import useIsSettleXAddress from '@nm-namshi-frontend/core/hooks/useIsSettleXAddress';
import Badge from '@nm-namshi-frontend/core/components/Badge';
import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import { TPageType } from '@nm-namshi-frontend/core/utils/analytics.types';

import MiniBagItem from './MiniBagItem';
import styles from './MiniBag.module.scss';
import ProgressiveDiscountWidget from '../progressiveDiscount/ProgressiveDiscountWidget';

type TProps = {
    hideContent?: boolean;
    href?: string;
    pageType?: CatalogType;
};

type TMiniBagClick = 'checkout' | 'goToBag';

const MiniBag = ({ hideContent, href, pageType }: TProps) => {
    const { getIsUserOnPage, locale, pathWithoutLocale } = useAppPathname();
    const { t } = useTranslation('common');
    const pathName = usePathname();
    const isOnBagPage = pathName.includes('/bag');
    const xPage = isOnBagPage ? 'cart' : 'other';

    const { atbModalProduct, atbSrc, setAtbModalProduct } = useLayoutStore();
    const [isMiniBagOpen, setIsMiniBagOpen] = useState(false);

    const ref = useOnclickOutside(() => handleClose());
    const onCheckout = usePreCheckout();

    const { isXAddressKeySattled } = useIsSettleXAddress();

    const { data, isFetching } = useQuery(
        [REACT_QUERY_KEYS.GET_ACTIVE_SESSION],
        () =>
            getApiInstance().session.getActiveSession({
                xPage,
            }),
        { enabled: isXAddressKeySattled },
    );

    useEffect(() => {
        const isUserOnBagPage = getIsUserOnPage(ROUTES.BAG);

        if (!isUserOnBagPage && data?.updatedSkus?.length) {
            setIsMiniBagOpen(true);
        }
    }, [data?.updatedSkus]);

    const {
        currencyCode,
        discountTracker,
        items = [],
        subtotalAmount,
        subtotalBeforeSavingAmount,
    } = (data as SessionDetailsResponse) || {};
    const bagItemsCount = items.reduce((count, item) => count + item.qty, 0);
    const doesBagHaveItems = bagItemsCount > 0;

    const handleClose = () => {
        setIsMiniBagOpen(false);
        if (atbModalProduct) setAtbModalProduct(null);
    };

    const onClickCheckoutCta = (e: any) => {
        trackClick('checkout');
        e.stopPropagation();
        if (data) {
            onCheckout(e, data);
        }
    };

    const trackClick = (action: TMiniBagClick) => {
        if (atbModalProduct) {
            trackEvent({
                event: 'genericButtonClick',
                action,
                pageType: atbSrc as TPageType,
                pagePath: pathWithoutLocale,
                sku: atbModalProduct.sku,
            });
        }
    };

    const renderIcon = () => (
        <AlteredLink locale={locale} href={href || ROUTES.BAG} className={styles.bagIcon}>
            <Icon name="shoppingBag" size={24} />
            <Badge show={doesBagHaveItems}>{bagItemsCount}</Badge>
        </AlteredLink>
    );

    if (!data) return renderIcon();

    const renderContent = () => {
        const show = doesBagHaveItems && (isMiniBagOpen || atbModalProduct);
        if (!show) return null;

        return (
            <div
                className={clsx(styles.miniBag, {
                    [styles.withProgressive]: !!discountTracker,
                })}
                onMouseLeave={handleClose}
            >
                <h3 className={styles.title}>
                    {t('bag')}: {t('_bag-item-count', { count: items.length })}
                </h3>
                <section className={clsx(styles.itemsContainer, { [styles.withProgressive]: !!discountTracker })}>
                    {items.map((BagItem) => (
                        <MiniBagItem key={BagItem.sku} item={BagItem} />
                    ))}
                </section>

                {!!discountTracker && !isFetching && (
                    <div className={styles.progressiveDiscountWrapper}>
                        <ProgressiveDiscountWidget discountTracker={discountTracker} pageType={pageType} />
                    </div>
                )}

                <section className={styles.footerContainer}>
                    <div className={styles.subtotalContainer}>
                        <b className={styles.label}>{t('mini-bag.total-label')}</b>
                        <ProductPrice
                            price={subtotalBeforeSavingAmount || subtotalAmount}
                            currency={currencyCode}
                            size="regular"
                        />
                    </div>
                    <div className={styles.buttonsContainer}>
                        <AlteredLink
                            className={styles.button}
                            locale={locale}
                            onClick={() => trackClick('goToBag')}
                            href={href || ROUTES.BAG}
                        >
                            <Button size="small" variant="secondary">
                                <Icon name="shoppingBag" size={18} />
                                {t('mini-bag.view-bag-cta')}
                            </Button>
                        </AlteredLink>
                        <AlteredLink
                            className={styles.button}
                            locale={locale}
                            href={ROUTES.CHECKOUT}
                            onClick={onClickCheckoutCta}
                        >
                            <Button size="small" variant="tertiary">
                                {t('checkout')}
                            </Button>
                        </AlteredLink>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <div ref={ref} data-testid="miniBagIcon" className={styles.container} aria-hidden="true">
            <div onMouseEnter={() => setIsMiniBagOpen(true)}>{renderIcon()}</div>
            {!hideContent && renderContent()}
        </div>
    );
};

export default MiniBag;
