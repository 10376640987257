// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AnalyticsResponse, CatalogResponse } from '@nm-namshi-frontend/services';
import { NavDepartment } from '@nm-namshi-frontend/core/types/navigation';

import { NON_CATALOG_ALL_SUBDEPARTMENT, RECENTLY_VIEWED_ITEMS_MAX_COUNT, RECENT_SEARCHES_MAX_COUNT } from '../config';
import { LOCALSTORAGE_ID } from '../constants/uiConstants';
import { TPageType } from './analytics.types';
import {
    addArrayItemToLocalStorage,
    getArrayFromLocalStorage,
    readFromLocalStorage,
    saveToLocalStorage,
} from './helper';

/**
 * Adds a catalog item's {collapseField: sku} key-value pair to cookie - this identifies it uniquely as a recently viewed PDP
 * @param {string} collapseField
 * @param {string} sku
 * @returns {void}
 */
export const addRecentlyViewedItem = (collapseField: string, sku: string): void => {
    addArrayItemToLocalStorage(
        { [`${collapseField}`]: sku },
        LOCALSTORAGE_ID.RECENTLY_VIEWED_ITEMS,
        RECENTLY_VIEWED_ITEMS_MAX_COUNT,
    );
};

/**
 * Adds keyword to cookie as a recent searched item
 * @param {string} keyword- Keyword to be added
 * @returns {void}
 */
export const addRecentSearch = (keyword: string): void => {
    addArrayItemToLocalStorage(keyword, LOCALSTORAGE_ID.RECENTLY_SEARCHED_TEXTS, RECENT_SEARCHES_MAX_COUNT);
};

/**
 * Fetches recently viewed item skus from cookie
 * @returns {string[]} - returns an array of skus
 */
export const getRecentlyViewedSkus = (): Array<string> => {
    const recentlyViewedItems = getArrayFromLocalStorage(LOCALSTORAGE_ID.RECENTLY_VIEWED_ITEMS);

    // Only a single key-value pair per parentId:sku combination is stored in each array element
    return recentlyViewedItems.map((keyValuePair) => Object.values<string>(keyValuePair)[0]);
};

/**
 * Fetches recently searched keywords from cookie
 * @returns {string[]} - returns an array of recent search strings
 */
export const getRecentSearches = (): string[] => getArrayFromLocalStorage(LOCALSTORAGE_ID.RECENTLY_SEARCHED_TEXTS);

/**
 * Removes a keyword or the whole list from cookie
 * @param {string} keyword- Keyword to be removed
 * @returns {void}
 */
export const deleteRecentSearch = (keyword?: string): void => {
    try {
        if (keyword) {
            let searches = JSON.parse(readFromLocalStorage(LOCALSTORAGE_ID.RECENTLY_SEARCHED_TEXTS) || '');
            if (searches && Array.isArray(searches)) {
                searches = searches.filter((value) => value !== keyword);
            } else {
                searches = [];
            }
            saveToLocalStorage(LOCALSTORAGE_ID.RECENTLY_SEARCHED_TEXTS, JSON.stringify(searches));
        } else {
            saveToLocalStorage(LOCALSTORAGE_ID.RECENTLY_SEARCHED_TEXTS, JSON.stringify([]));
        }
    } catch (exception) {
        saveToLocalStorage(LOCALSTORAGE_ID.RECENTLY_SEARCHED_TEXTS, JSON.stringify([]));
    }
};

/**
 * Clears all recent searches
 * @returns {void}
 */
export const clearRecentSearches = (): void => {
    saveToLocalStorage(LOCALSTORAGE_ID.RECENTLY_SEARCHED_TEXTS, []);
};

/**
 * @typedef {Object} PLPAnalyticsData
 * @property {TPageType} pageType
 * @property {number} plpPage
 * @property {string} searchTerm
 * @property {AnalyticsResponse['td']} attributionData
 */
/**
 * Return analytics object specific to track PLP events
 * @param {CatalogResponse} catalogResponse
 * Catalog Data
 * @return {PLPAnalyticsData}
 * PLP relevant analytics data
 */
export const getPLPAnalyticsData = (
    catalogResponse: CatalogResponse,
): {
    pageType: TPageType;
    plpPage?: number; // In case of a PLP being rendered
    searchTerm?: string;
    attributionData: AnalyticsResponse['td'];
} => {
    let currentPage;
    let attributionData;

    if (!!catalogResponse?.nav && 'currentPage' in catalogResponse?.nav?.searchMeta) {
        currentPage = catalogResponse?.nav?.searchMeta.currentPage;
    }

    if (!!catalogResponse?.analytics && 'td' in catalogResponse?.analytics) {
        attributionData = catalogResponse.analytics.td;
    }

    return {
        plpPage: currentPage,
        pageType: 'listing',
        searchTerm: catalogResponse.nav?.searchMeta.query,
        attributionData,
    };
};

/**
 * Removes the page number from the URL query params from catalog specific relative URL's
 * @param {string} inputUrl- Input relative URL
 * @returns {string}
 */
export const resetPageNumber = (inputUrl: string): string => {
    // Adding a the following absolute URL, as we just need it to reliably remove the page query param
    try {
        const url = new URL(`https://www.namshi.com${inputUrl}`);
        url.searchParams.delete('page');

        const isSlashTrailingInPathname = url.pathname.endsWith('/');
        return `${url.pathname}${isSlashTrailingInPathname ? '' : '/'}${url.search}`;
    } catch (e) {
        return inputUrl;
    }
};

/**
 * @typedef {Object} UrlParams
 * @property {string} searchDepartmentId
 * @property {string} globalSubdepartmentId
 * @property {string} text
 */
/**
 * Reusable function to cosntruct search url considering current department and subdepartment selection
 * @param {UrlParams} urlParams
 * Search Params
 * @return {string}
 * Search URL
 */
export const constructSearchUrl = ({
    searchDepartmentId,
    selectedGender,
    text,
}: {
    searchDepartmentId: string;
    selectedGender: string | null;
    text: string;
}) => {
    const encodedQuery = encodeURIComponent(text);
    const genderQueryParamsString = selectedGender ? `&selected_gender=${selectedGender}` : '';

    return `${searchDepartmentId}/search?q=${encodedQuery}${genderQueryParamsString}`;
};

/**
 * Gets default search dropdown option
 * @param {NavDepartment} selectedDepartmentTabData- selected department data
 * @param {string} selectedSubDepartmentId- selected subdepartment ID
 * @returns {string}
 */
export const getDefaultSearchSubdepartment = (
    selectedDepartmentTabData: NavDepartment,
    selectedSubDepartmentId?: string,
) => {
    if (!selectedDepartmentTabData.subDepartments?.length) {
        return selectedDepartmentTabData.defaultSearchDropdownSelection || NON_CATALOG_ALL_SUBDEPARTMENT;
    }

    const subdepartmentTabData = selectedDepartmentTabData.subDepartments?.find(
        ({ id }) => id === selectedSubDepartmentId,
    );

    if (subdepartmentTabData) {
        return subdepartmentTabData.defaultSearchDropdownSelection || NON_CATALOG_ALL_SUBDEPARTMENT;
    }

    return NON_CATALOG_ALL_SUBDEPARTMENT;
};
