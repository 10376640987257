import React from 'react';

import clsx from 'clsx';

import styles from './Badge.module.scss';

type TBadgeProps = {
    children: React.ReactNode;
    show: boolean;
    isWarning?: boolean;
};

const Badge: React.FC<TBadgeProps> = ({ children, isWarning, show }) => (
    <div className={clsx(styles.badge, show && styles.show, isWarning && styles.warning)}>{children}</div>
);

export default Badge;
