'use client';

import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import dynamic from 'next/dynamic';

import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getLocaleConfig, getLocaleCountryCode, getAllRegionCodes } from '@nm-namshi-frontend/core/utils/locale';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import Fader from '@nm-namshi-frontend/core/page_components/shared/fader/Fader';
import getImages from '@nm-namshi-frontend/core/utils/images';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { MAP_CONFIG } from '@nm-namshi-frontend/core/config';
import useUpdateAddressKey from '@nm-namshi-frontend/core/hooks/useUpdateAddressKey';
import StoreClosedModal from '@nm-namshi-frontend/core/page_components/shared/CountrySelection/StoreClosedModal';
import Loader from '@nm-namshi-frontend/core/components/loaders/Loader';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';
import { clearGeoLocCookies } from '@nm-namshi-frontend/core/utils/geoLocCookies';

import styles from './LocationSelector.module.scss';

const ChangeAddressModal = dynamic(
    () => import('@nm-namshi-frontend/core/page_components/shared/addresses/ChangeAddressModal'),
    {
        ssr: false,
    },
);

const LocationSelector = () => {
    const { t } = useTranslation('common');
    const { locale, pathWithoutLocale } = useAppPathname();
    const { updateAddressKey } = useUpdateAddressKey();

    const [isChangeAddressModalOpen, setIsChangeAddressModalOpen] = useState(false);
    const [isCountrySelectorDropdownOpen, setIsCountrySelectorDropdownOpen] = useState(false);
    const [openStoreClosedModal, setStoreClosedModal] = useState(false);

    const customerData = useCustomerStore((store) => store.customerData);
    const isLoadingCustomerData = useCustomerStore((store) => store.isLoadingCustomerData);

    const { apiRegion: regionCode, isArabic, lang } = getLocaleConfig(locale);
    const localeCountryCode = getLocaleCountryCode(locale);

    const isUserLoggedIn = !!customerData;

    const { data: customerAddressData } = useQuery(
        [REACT_QUERY_KEYS.GET_CUSTOMER_ADDRESSES],
        () => getApiInstance().customer.getCustomerAddresses({}),
        {
            onSuccess: (data) => {
                const newSelectedAddressKey = data?.selected?.addressKey || '';
                updateAddressKey(newSelectedAddressKey);
            },
            enabled: isUserLoggedIn,
            useErrorBoundary: false,
        },
    );

    const renderCtaText = () => {
        if (
            !isUserLoggedIn ||
            isEmpty(customerAddressData?.data) ||
            (!isEmpty(customerAddressData?.data) && !customerAddressData?.selected)
        ) {
            if (!regionCode) {
                return '';
            }
            // Guest user sees the default city as per locale
            // or
            // Logged in user who has no addresses saved or selected/default address
            const { area_ar, area_en } = MAP_CONFIG.centralGeoLocations[regionCode];
            return (
                <Trans t={t} i18nKey="location-selector._deliver-to-label">
                    <strong>{{ area: isArabic ? area_ar : area_en }}</strong>
                </Trans>
            );
        }

        // Logged in user with a default selected address
        return (
            <Trans t={t} i18nKey="location-selector._deliver-to-label">
                <strong>{{ area: customerAddressData?.selected.city.name }}</strong>
            </Trans>
        );
    };

    const renderClosedStoreItems = () => {
        const handleBtnClick = () => {
            setIsCountrySelectorDropdownOpen(false);
            setStoreClosedModal(true);
        };

        return (
            <>
                <div key="iraq" className={clsx(styles.item_wrapper)}>
                    <button type="button" className={styles.item} onClick={handleBtnClick}>
                        <img className={styles.flag} alt="iraq" src={getImages().flags.iq} width={30} height={30} />
                        <span className={styles.text}>{t(`location-selector.country-name-iraq`)}</span>
                    </button>
                </div>
                <div key="rest_of_the_world" className={clsx(styles.item_wrapper)}>
                    <button type="button" className={styles.item} onClick={handleBtnClick}>
                        <Icon className={styles.flag} name="restOfTheWorld" size={30} />
                        <span className={styles.text}>{t(`location-selector.rest-of-the-world`)}</span>
                    </button>
                </div>
            </>
        );
    };

    const renderCountries = () => {
        const regionCodeArray = getAllRegionCodes();

        return (
            <div className={clsx(styles.dropdownItems)}>
                {regionCodeArray.map(({ apiRegionCode, seoRegionCode }) => (
                    <div
                        key={apiRegionCode}
                        className={clsx(
                            styles.item_wrapper,
                            locale.startsWith(seoRegionCode) && styles.selected_country,
                        )}
                    >
                        <a
                            onClick={clearGeoLocCookies}
                            href={`/${seoRegionCode}-${lang}${pathWithoutLocale}`}
                            className={styles.item}
                        >
                            <img
                                className={styles.flag}
                                alt={seoRegionCode}
                                src={getImages().flags[apiRegionCode]}
                                width={30}
                                height={30}
                            />
                            <span className={styles.text}>{t(`location-selector.country-name-${seoRegionCode}`)}</span>
                        </a>
                    </div>
                ))}
                {renderClosedStoreItems()}
            </div>
        );
    };

    const renderCountrySelectorDropdown = () => (
        <>
            <div
                className={clsx(styles.dropdownOverlay, isCountrySelectorDropdownOpen ? styles.visible : styles.hidden)}
                onClick={() => isCountrySelectorDropdownOpen && setIsCountrySelectorDropdownOpen(false)}
                aria-hidden="true"
            />
            <Fader
                isActive={isCountrySelectorDropdownOpen}
                containerClass={clsx(
                    styles.dropdownContainer,
                    isUserLoggedIn ? styles.anchorToFlag : styles.anchorToDeliverToText,
                )}
            >
                {renderCountries()}
            </Fader>
        </>
    );

    const renderLocationSelector = () => (
        <div className={styles.locationSelectorWrapper}>
            <div className={styles.innerContainer}>
                {isUserLoggedIn && (
                    <button
                        type="button"
                        className={styles.locationSelectorCta}
                        onClick={() => setIsChangeAddressModalOpen(true)}
                    >
                        <span className={styles.currentLocationLabel}>{renderCtaText()}</span>
                        <Icon name="chevronDown" size={12} color="white" />
                    </button>
                )}
                {!!localeCountryCode && (
                    <button
                        type="button"
                        className={styles.locationSelectorCta}
                        onClick={() => setIsCountrySelectorDropdownOpen(true)}
                    >
                        <img alt="empty-search" src={getImages().flags[localeCountryCode]} width={30} height={30} />
                        <Icon name="chevronDown" size={12} color="white" />
                    </button>
                )}
            </div>
            {renderCountrySelectorDropdown()}
        </div>
    );

    const renderChangeAddressModal = () => (
        <ChangeAddressModal
            open={isChangeAddressModalOpen}
            onClose={() => setIsChangeAddressModalOpen(false)}
            usesSession
        />
    );

    if (isLoadingCustomerData) {
        return <Loader color="var(--clr-white)" />;
    }

    return (
        <div>
            {renderChangeAddressModal()}
            {renderLocationSelector()}
            <StoreClosedModal open={openStoreClosedModal} onClose={() => setStoreClosedModal(false)} />
        </div>
    );
};

export default LocationSelector;
