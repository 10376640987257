import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import styles from './Fader.module.scss';

type TProps = {
    isActive: boolean; // Source of truth for the children to fade in or out of existence; an external control over component state
    children: JSX.Element;
    containerClass?: string;
    visibleChildrenClassOverride?: string; // Override the visible state styles
    invisibleChildrenClassOverride?: string; // Override the invisible state styles
    timeToEntryTransitionOverride?: number;
    timeToExitTransitionOverride?: number;
};

const DEFAULT_TIME_TO_ENTRY_TRANSITION = 0;
const DEFAULT_TIME_TO_EXIT_TRANSITION = 200;

const Fader = ({
    children,
    containerClass,
    invisibleChildrenClassOverride,
    isActive,
    timeToEntryTransitionOverride = DEFAULT_TIME_TO_ENTRY_TRANSITION,
    timeToExitTransitionOverride = DEFAULT_TIME_TO_EXIT_TRANSITION,
    visibleChildrenClassOverride,
}: TProps) => {
    const [isChildInDOM, setIsChildInDOM] = useState(false); // Used for internal control over component state
    const [isChildVisible, setIsChildVisible] = useState(false);

    const timeoutRef = useRef(0); // Tracks timeout for when the user opens bag explicitly

    useEffect(() => {
        // This effect responds to external input
        if (isActive) {
            setIsChildInDOM(true);
        } else {
            setIsChildVisible(false);
        }
    }, [isActive]);

    useEffect(() => {
        // Order of events in this effect :
        // 1. Adds children to DOM
        // 2. Fades then *into* existence
        if (isChildInDOM) {
            timeoutRef.current = window.setTimeout(() => {
                setIsChildVisible(isChildInDOM);
            }, timeToEntryTransitionOverride);
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isChildInDOM]);

    useEffect(() => {
        // Order of events in this effect :
        // 1. Fades children *out*
        // 2. Removes it from DOM
        if (!isChildVisible) {
            timeoutRef.current = window.setTimeout(() => {
                setIsChildInDOM(isChildVisible);
            }, timeToExitTransitionOverride);
        }

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [isChildVisible]);

    if (!children || !isChildInDOM) return null;

    return (
        <div className={clsx(styles.faderContainer, containerClass)}>
            <div
                className={clsx(
                    isChildVisible
                        ? visibleChildrenClassOverride || styles.visibleChildren
                        : invisibleChildrenClassOverride || styles.invisibleChildren,
                )}
            >
                {children}
            </div>
        </div>
    );
};

export default Fader;
